import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Text } from "components";
import StarIcon from "svgs/sherm-star-icon.svg";

const AboutSherm = () => {
  return (
    <section className={sectionWrapStyle}>
      <Container leftMargin>
        <Text
          variant="p14"
          value="About the SHERM Programme"
          className="uppercase"
        />

        <div className={headingStyle}>
          <Text
            variant="h4"
            value="Designed Safety, health environment and risk management professionals for an exciting career in any industry"
            color="primary-900"
          />
        </div>

        <div className={paragraphStyle}>
          <Text
            variant="p14"
            value="The SHERM Programme has a global syllabus that equips participants with essential on-demand technical and transferable skills, as well as the personal qualities required to manage risk to international standards and compete anywhere in the world and across all industries. The primary focus is on the practical application of safety, health, environment, risk management, and sustainability practices to real-world issues confronting societies, workplaces, and industries."
          />
        </div>

        <PageImage />
      </Container>

      <PageImage mobile />
    </section>
  );
};

const PageImage = ({ mobile }) => (
  <div
    className={`${imageWrapStyle}  ${mobile ? "lg:hidden" : "hidden lg:block"}`}
  >
    <StaticImage src="../../../assets/images/pngs/sherm/sherm-about-the-program.webp" />
    <StarIcon className={starIconStyle} />
  </div>
);

const sectionWrapStyle = ctl(`
  lg:pt-[155px]
  pt-[77px]
  lg:pb-[149px] 
  pb-[83px] 
`);
const imageWrapStyle = ctl(`
  relative 
  mt-[62px]
`);
const starIconStyle = ctl(`
  absolute 
  lg:right-8 
  -right-5
  -top-[68px]
  scale-50
  lg:scale-100
`);
const headingStyle = ctl(`
  pt-10 
  pb-4 
  xl:max-w-[901px] 
  md:max-w-[700px] 
  max-w-[90%]
`);
const paragraphStyle = ctl(`
  md:max-w-[631px] 
  max-w-[90%]
`);

export { AboutSherm };
