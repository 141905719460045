import React from "react";
import { PageHeaderOne, Br } from "components";

const ShermHeader = () => {
  return (
    <PageHeaderOne
      button={{ text: "Explore Courses", size: "large", to: "/" }}
      heading="Safety, Health, Environment and Risk Management Programme"
      paragraph={
        <>
          Grow your HSE & Risk Management knowledge with <Br on="desktop" />
          courses in the SHERM Program
        </>
      }
    />
  );
};

export { ShermHeader };
