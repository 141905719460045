import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Container, ReadMore, Text } from "components";
import Foundation from "svgs/SHERM-Foundation.svg";
import Certificate from "svgs/SHERM-Certificate.svg";
import Diploma from "svgs/SHERM-Diploma.svg";

const ShermCourses = () => {
  return (
    <section className={sectionWrapperStyle}>
      <Container>
        <Text
          variant="h3"
          value="Courses That Suit Your Interest"
          color="primary-900"
          className={headerStyle}
        />

        <div className={courseCategoryWrapperStyle}>
          {courseCategory.map((course, index) => (
            <div key={`shermCourses_${index}`} className={courseCategoryStyle}>
              {course.icon}
              <Text variant="h5" value={course.name} className={textStyle} />
            </div>
          ))}
        </div>

        <div className={linkWrapperStyle}>
          <ReadMore text="Register Now" to="/register" color="secondary-700" />
        </div>
      </Container>
    </section>
  );
};

const iconStyle = ctl(`
  mx-auto
`);
const sectionWrapperStyle = ctl(`
px-[81px]
lg:px-0
`);
const courseCategoryWrapperStyle = ctl(`
mt-[94px] 
flex 
flex-col
lg:flex-row
justify-between
items-center
`);
const courseCategoryStyle = ctl(`
mb-[68px]
lg:mb-0
`);
const linkWrapperStyle = ctl(`
text-center 
lg:mt-[78px] 
mb-[122px]
`);

const headerStyle = ctl(`
text-center 
min-w-[212px]
`);
const textStyle = ctl(`
mt-[16px] 
text-center
`);

const courseCategory = [
  { name: "SHERM Foundation", icon: <Foundation className={iconStyle} /> },
  { name: "SHERM Certificate", icon: <Certificate className={iconStyle} /> },
  { name: "SHERM Diploma", icon: <Diploma className={iconStyle} /> },
];

export { ShermCourses };
