import React from "react";

import { Layout, Kickstart } from "components";
import {
  ShermHeader,
  AboutSherm,
  ShermObjectives,
  ShermCourses,
} from "templates/sherm";

const Sherm = () => (
  <Layout title="SHERM">
    <ShermHeader />
    <AboutSherm />
    <ShermObjectives />
    <ShermCourses />
    <Kickstart />
  </Layout>
);

export default Sherm;
