import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Container, Text } from "components";
import ObjectivesIcon from "svgs/sherm-objective.svg";

const ShermObjectives = () => {
  return (
    <Container>
      <section className={sectionWrapStyle}>
        <Text
          variant="h4"
          value="Objectives of the SHERM Programme"
          color="primary-900"
          className={headingStyle}
        />

        <ul className={objectivesWrapStyle}>
          <Objective text="Empower applicants to create goals, strategies, and metrics for identifying and managing risks across multiple industries." />

          <Objective text="Teach interested professionals on the decision making processes involved in a competitive business environment." />

          <Objective text="Educate participants on advanced safety, health, environment, and risk management principles, practices, and techniques." />

          <Objective text="Prepare sustainability professionals for the challenges that our world faces in terms of safety, health, the environment, and risk management." />
        </ul>
      </section>
    </Container>
  );
};

const Objective = ({ text }) => (
  <li className={objectiveListStyle}>
    <span>
      <ObjectivesIcon />
    </span>
    <Text variant="p14" value={text} />
  </li>
);

const sectionWrapStyle = ctl(`
  lg:flex 
  xl:gap-6 
  pt-4 
  xl:pb-[259px] 
  pb-[113px]
`);
const headingStyle = ctl(`
  md:max-w-[336px] 
  max-w-[226px]
`);
const objectiveListStyle = ctl(`
  flex
  gap-[18px]
  lg:max-w-[345px]
  sm:max-w-[500px]
  max-w-[302px]
`);
const objectivesWrapStyle = ctl(`
  grid
  lg:grid-cols-2
  xl:gap-y-[60px]
  lg:gap-y-10
  xl:gap-x-[144px]
  gap-4
  lg:mt-10 mt-6
`);

export { ShermObjectives };
